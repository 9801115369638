<template lang="pug">
  v-card
    v-card-title
      h2.card-title Фільтр
    v-card-content
      v-text-field(
        label="Ім’я постачальника"
        v-model="name")
    v-card-actions
      v-btn Додати фільтр

</template>

<script>
export default {
  name: 'FiltersModal',

  data: () => ({
    name: ''
  })
}
</script>

<style lang="scss" scoped>
</style>
